/* @TODO: Remove wieght speicfic font families */

@font-face {
  font-family: 'Walsheim-Light';
  src: url('/static/fonts/GT-Walsheim/GT-Walsheim-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Walsheim-Regular';
  src: url('/static/fonts/GT-Walsheim/GT-Walsheim-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Walsheim-Medium';
  src: url('/static/fonts/GT-Walsheim/GT-Walsheim-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Walsheim-Bold';
  src: url('/static/fonts/GT-Walsheim/GT-Walsheim-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WalsheimPro-Light';
  src: url('/static/fonts/GT-Walsheim-Pro/GTWalsheimPro-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WalsheimPro-Regular';
  src: url('/static/fonts/GT-Walsheim-Pro/GTWalsheimPro-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WalsheimPro-Medium';
  src: url('/static/fonts/GT-Walsheim-Pro/GTWalsheimPro-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'WalsheimPro-Bold';
  src: url('/static/fonts/GT-Walsheim-Pro/GTWalsheimPro-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('/static/fonts/Inter/inter-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url('/static/fonts/Inter/inter-medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url('/static/fonts/Inter/inter-semibold.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('/static/fonts/Inter/inter-bold.woff2') format('woff2');
}
